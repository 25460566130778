<template>
  <div>
    <div class="title flexB" style="display: block;">
      <h1>{{ $t("top-select-device-manager-drone") }}</h1>

    </div>
    <div class="contents droneList">
      <div class="box one filter" style="display: flex;flex-direction: row;flex-wrap: wrap;">
        <div class="flex">
          <p class="bold">{{ $t("search-keyword-title") }}</p>
          <select v-model="keywordType">
            <option value="all">{{ $t("search-keyword-all") }}</option>
            <option value="deviceId">{{ $t("device-data-id") }}</option>
            <option value="serial">{{ $t("device-data-serial-num") }}</option>
            <option value="manufacturerName">{{ $t("device-data-manufacturer") }}</option>
            <option value="modelName">{{ $t("device-data-model-name") }}</option>
            <option value="deviceName">{{ $t("device-data-device-name") }}</option>
            <option value="ownerDepartment">{{ $t("device-data-manager-dept-name") }}</option>
            <option value="ownerName">{{ $t("device-data-manager-name") }}</option>
          </select>
          <input
              type="text"
              v-model="keyword"
              @keydown.enter="search"
          />
        </div>
        <div class="flexC">
          <p class="bold">{{ $t("search-regdate-title") }}</p>
          <Datepicker
              v-model="startYmd"
              :language="ko"
              :format="customFormatter"
              placeholder="YYYY-MM-DD"
          ></Datepicker>
          <span>~</span>
          <Datepicker
              v-model="endYmd"
              :language="ko"
              :format="customFormatter"
              placeholder="YYYY-MM-DD"
          ></Datepicker>
        </div>
        <div class="buttonWrap" style="display: flex; margin-top: 0;">
            <button class="point medium" @click="search" style="width: 120px; margin-left: 20px;">
              {{ $t("btn-searching") }}
            </button>
            <button class="medium margin6" @click="reset" style="width: 120px; ">{{ $t("btn-reset") }}</button>
          </div>
      </div>
      <div class="box one">
        <h2 class="bold">
          {{ $t("data-total") }}
          <span class="blue">{{ total }}</span
          >{{ $t("data-case-ea") }}
          <router-link to="deviceRegister">
            <button class="small right">{{ $t("btn-register") }}</button>
          </router-link>
        </h2>
        <!-- <div class="none">
            <p>{{ $t("search-not-found") }}</p>
        </div>-->
        <table style="table-layout: fixed">
          <tr>
            <th>No</th>
            <th>{{ $t("device-data-id") }}</th>
            <th>{{ $t("device-data-serial-num") }}</th>
            <th>{{ $t("device-data-manufacturer") }}</th>
            <th>{{ $t("device-data-model-name") }}</th>
            <th>{{ $t("device-data-device-name") }}</th>
            <th>{{ $t("device-data-manager-dept-name") }}</th>
            <th>{{ $t("device-data-manager-name") }}</th>
            <th>{{ $t("device-data-photo") }}</th>
            <th>{{ $t("device-data-device-regdate") }}</th>
          </tr>
          <tr v-if="total == 0">
            <td colspan="10" style="text-align: center;">
              {{ $t("search-not-found") }}
            </td>
          </tr>
          <tr v-for="(data, i) in deviceList" :key="i">
            <td>{{ total - (size * pageNumber + i) }}</td>
            <td>{{ data.deviceId }}</td>
            <td>{{ data.serial }}</td>
            <td>{{ data.manufacturerName }}</td>
            <td>
              {{ data.modelName }}
            </td>
            <td>
              <a @click="handleDetail(data.deviceId)" class="link">{{
                  data.deviceName
                }}</a>
            </td>
            <td>{{ data.ownerDepartmentInfo.name }}</td>
            <td>{{ data.ownerName }}</td>
            <td>
              <div>
                <img
                    :src="getDeviceImg(data)"
                    alt="썸네일"
                    class="deviceImg"
                />
              </div>
            </td>
            <td>{{ moment(data.registerDate).format("YYYY.MM.DD HH:mm") }}</td>
          </tr>
        </table>
        <div class="pagination">
          <el-pagination
              small
              layout="prev, pager, next"
              :total="total"
              :page-size="10"
              :currentPage = "pageNumber + 1"
              @current-change="handleCurrentChange"
          ></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.title{margin-top: 0;}
</style>
<script>
import moment from "moment";
import { ko } from "vuejs-datepicker/dist/locale";
import Datepicker from "vuejs-datepicker";
import { fetchDeviceList, getDeviceImgUrl, getDeviceImgDefault } from "@/api/device";
import { baseUrl } from "@/config/env";

export default {
  components: { Datepicker },
  name: "DroneList",
  data() {
    return {
      baseUrl: baseUrl,
      accountId: localStorage.getItem("accountId"),
      ko: ko,
      moment: moment,
      startYmd: null,
      endYmd: null,
      deviceList: [],
      total: 0,
      pageNumber: 0,
      size : 10,
      keywordType: "all",
      keyword: null,
    };
  },
  created() {
    this.$store.dispatch("store/SET_NAVBAR", { nav: 5, sub: 0 });
    this.preset();
  },
  mounted() {
    this.getDeviceList();
  },
  methods: {
    getDeviceImg(data){
      return data.defaultIsImage ? getDeviceImgDefault(data.manufacturerId, data.modelId) : getDeviceImgUrl(this.accountId, data.deviceId) + '?date='+(new Date)
    },
    preset(){
      var localStorageData = localStorage.getItem("device_searchData");
      if(localStorageData != undefined) {
        var searchData = JSON.parse(localStorageData);
        this.keyword = searchData.keyword;
        this.keywordType = searchData.keywordType;
        this.pageNumber = searchData.pageNumber;
        this.startYmd =  searchData["dateRange.from"];
        this.endYmd =  searchData["dateRange.to"];
      }
    },
    persist(params){
      localStorage.setItem("device_searchData", JSON.stringify(params)); // 로컬 스토리지에 저장
    },

    customFormatter(date) {
      return moment(date).format("YYYY년 MM월 DD일");
    },
    search(){
      if(this.startYmd != null || this.endYmd != null) {
        if (this.startYmd == null || this.endYmd == null || this.startYmd > this.endYmd) {
          return alert(this.$t("alert-message-check-regdate"));
        }
      }
      this.pageNumber = 0;
      this.getDeviceList();
    },
    getDeviceList() {
      let params = {
        pageNumber: this.pageNumber,
        pageSize: this.size,
        keywordType: this.keywordType,
        keyword: this.keyword,
      };

      params["dateRange.from"] = null;
      params["dateRange.to"] = null;

      if (this.startYmd != null && this.endYmd != null) {
        let from = this.startYmd ? moment(this.startYmd).format("YYYY-MM-DD") : null;
        let to = this.endYmd ? moment(this.endYmd).format("YYYY-MM-DD") : null;
        params["dateRange.from"] = from;
        params["dateRange.to"] = to;
      }
      this.persist(params);
      fetchDeviceList(params).then((res) => {
        if (res.data.result == 0) {
          this.total = res.data.data.total;
          this.deviceList = res.data.data.content;
        } else {
          let message = res.data.message;
          alert(message);
        }
      });
    },
    handleCurrentChange(val) {
      this.pageNumber = val - 1;
      this.getDeviceList();
    },
    reset() {
      this.pageNumber = 0;
      this.keywordType = "all";
      this.keyword = null;
      this.startYmd = null;
      this.endYmd = null;
      this.getDeviceList();
    },
    handleDetail(id) {
      this.$router.push({ name: "deviceRegister", query: { deviceId: id } });
    },
  },
};
</script>
